/**
 * Transaction process graph for plain inquiries:
 *   - default-inquiry
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // A customer can initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE_WITHOUT_PAYMENT: 'transition/inquire-without-payment',
  RESERVE_PROJECT: 'transition/reserve-project',
  BOOK_ON_SITE_REVIEW: 'transition/book-on-site-review',
  COMPLETE_ON_SITE_REVIEW: 'transition/complete-on-site-review',
  ACCEPT_ORIGINAL_PRICE: 'transition/accept-original-price',
  REQUEST_PRICE_CHANGE: 'transition/request-price-change',
  ACCEPT_PRICE_CHANGE_REQUEST: 'transition/accept-price-change-request',
  DECLINE_PRICE_CHANGE_REQUEST: 'transition/decline-price-change-request',
  REQUEST_PRICE_CHANGE_FROM_DECLINED: 'transition/request-price-change-from-declined',
  INITIAL_DEPOSIT_PAYMENT_REQUEST: 'transition/initial-deposit-payment-request',
  INITIAL_DEPOSIT_PAYMENT_REQUEST_FROM_PRICE_CHANGE: "transition/initial-deposit-payment-request-from-price-change",
  COMPLETE_MATCH: 'transition/complete-match',
  COMPLETE_MATCH_NO_INITIAL_DEPOSIT: 'transition/complete-match-no-initial-deposit',
  COMPLETE_MATCH_NO_INITIAL_DEPOSIT_FROM_PRICE_CHANGE: 'transition/complete-match-no-initial-deposit-from-price-change'
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  FREE_INQUIRY: 'free-inquiry',
  RESERVED: 'reserved',
  BOOKED_ON_SITE_REVIEW: 'booked-on-site-review',
  COMPLETED_ON_SITE_REVIEW: 'completed-on-site-review',
  ACCEPTED_ORIGINAL_PRICE: 'accepted-original-price',
  REQUESTED_PRICE_CHANGE: 'requested-price-change',
  ACCEPTED_PRICE_CHANGE_REQUEST: 'accepted-price-change-request',
  DECLINED_PRICE_CHANGE_REQUEST: 'declined-price-change-request',
  REQUESTED_PRICE_CHANGE_FROM_DECLINED: 'requested-price-change-from-declined',
  INITIAL_DEPOSIT_PAYMENT_PENDING: 'initial-deposit-payment-pending',
  MATCH_COMPLETED: 'match-completed',
  MATCH_COMPLETED_NO_INITIAL_DEPOSIT: 'match-completed-no-initial-deposit',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-inquiry/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE_WITHOUT_PAYMENT]: states.FREE_INQUIRY,
      },
      // on: {
      //   [transitions.BOOK_ON_SITE_REVIEW]: states.BOOKED_ON_SITE_REVIEW,
      // },
    },
    [states.FREE_INQUIRY]: { type: 'final' },

    [states.RESERVED]: {
      on: {
        [transitions.RESERVE_PROJECT]: states.RESERVED,
      },
    },

    [states.BOOKED_ON_SITE_REVIEW]: {
      on: {
        [transitions.BOOK_ON_SITE_REVIEW]: states.BOOKED_ON_SITE_REVIEW,
      },
    },

    [states.COMPLETED_ON_SITE_REVIEW]: {
      on: {
        [transitions.COMPLETE_ON_SITE_REVIEW]: states.COMPLETED_ON_SITE_REVIEW,
      },
    },

    [states.ACCEPTED_ORIGINAL_PRICE]: {
      on: {
        [transitions.ACCEPT_ORIGINAL_PRICE]: states.ACCEPTED_ORIGINAL_PRICE,
      },
    },
    [states.REQUESTED_PRICE_CHANGE]: {
      on: {
        [transitions.REQUEST_PRICE_CHANGE]: states.REQUESTED_PRICE_CHANGE,
      },
    },

    [states.ACCEPTED_PRICE_CHANGE_REQUEST]: {
      on: {
        [transitions.ACCEPT_PRICE_CHANGE_REQUEST]: states.ACCEPTED_PRICE_CHANGE_REQUEST,
      },
    },

    [states.DECLINED_PRICE_CHANGE_REQUEST]: {
      on: {
        [transitions.DECLINE_PRICE_CHANGE_REQUEST]: states.DECLINED_PRICE_CHANGE_REQUEST,
      },
    },

    [states.REQUESTED_PRICE_CHANGE_FROM_DECLINED]: {
      on: {
        [transitions.REQUEST_PRICE_CHANGE_FROM_DECLINED]: states.REQUESTED_PRICE_CHANGE,
      },
    },

    [states.INITIAL_DEPOSIT_PAYMENT_PENDING]: {
      on: {
        [transitions.INITIAL_DEPOSIT_PAYMENT_REQUEST]: states.INITIAL_DEPOSIT_PAYMENT_PENDING,
        [transitions.INITIAL_DEPOSIT_PAYMENT_REQUEST_FROM_PRICE_CHANGE]: states.INITIAL_DEPOSIT_PAYMENT_PENDING,
      },
    },

    [states.MATCH_COMPLETED_NO_INITIAL_DEPOSIT]: {
      on: {
        [transitions.COMPLETE_MATCH_NO_INITIAL_DEPOSIT]: states.MATCH_COMPLETED_NO_INITIAL_DEPOSIT,
        [transitions.COMPLETE_MATCH_NO_INITIAL_DEPOSIT_FROM_PRICE_CHANGE]: states.MATCH_COMPLETED_NO_INITIAL_DEPOSIT,
      },
      type: 'final'
    },

    [states.MATCH_COMPLETED]: {
      on: {
        [transitions.COMPLETE_MATCH]: states.MATCH_COMPLETED,
      },
      type: 'final'
    },
    // [states.RESERVED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [transitions.INQUIRE_WITHOUT_PAYMENT].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
// NOTE: this functions is added just for the sake of consistency
export const isCustomerReview = transition => {
  return false;
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
// NOTE: this functions is added just for the sake of consistency
export const isProviderReview = transition => {
  return false;
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
// NOTE: this functions is added just for the sake of consistency
export const isPrivileged = transition => {
  return false;
};

// Check when transaction is completed (booking over)
// NOTE: this functions is added just for the sake of consistency
export const isCompleted = transition => {
  return false;
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
// NOTE: this functions is added just for the sake of consistency
export const isRefunded = transition => {
  return false;
};

// NOTE: this functions is added just for the sake of consistency
// We don't know if inquiry is on-going or complete
export const statesNeedingProviderAttention = [];
