import { post } from "../../util/api";
import { createSlug } from "../../util/urlHelpers";

const marketPlaceUrl = process.env.REACT_APP_MARKETPLACE_ROOT_URL;

const getEditListingUrl = (listing, tab) => {
  // http://localhost:3000/l/test-email-2/66712242-6ede-424c-bc0f-88ee601cecf8/draft/quote
  const slug = createSlug(listing.attributes.title);
  return `${marketPlaceUrl}/l/${slug}/${listing.id.uuid}/draft/${tab}`;
}

const getListingUrl = (listing) => {
  // http://localhost:3000/l/test-email-2/66712242-6ede-424c-bc0f-88ee601cecf8
  const slug = createSlug(listing.attributes.title);
  return `${marketPlaceUrl}/l/${slug}/${listing.id.uuid}`;
}

const getPostNewProjectUrl = () => {
  return `${marketPlaceUrl}/l/new`
}

const getEmailTemplate = (bodyTitle, body, url, buttonText) => {

  return `<div>
<table style="background-color:#fff;margin:0 auto;padding:24px 12px 0;font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
  <tbody>
    <tr>
      <td>
        <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="max-width:600px;margin:0 auto">
          <tbody><tr style="width:100%">
            <td>
              <h1 style="color:#484848;font-size:26px;line-height:1.3;font-weight:700">
              ${bodyTitle}
              </h1>
              <p style="font-size:16px;line-height:1.4;margin:16px 0;color:#484848">
              ${body}
              </p>

              <p style="font-size:16px;line-height:1.4;margin:0;  padding:0; color:#484848">Best,</p> 
              <p  style="font-size:16px;line-height:1.4;margin:0; padding:0; color:#484848">Your Tackl team</p

              <table style="padding:16px 0 0" align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tbody>
                  <tr>
                    <td>
                      <a href="${url}" style="color:#fff;background-color:#007df2;border-radius:4px;font-size:15px;text-decoration:none;text-align:center;display:inline-block;min-width:210px;padding:0px 0px;line-height:100%;max-width:100%" target="_blank" data-saferedirecturl="https://www.google.com/url?q=${url}&amp;source=gmail&amp;ust=1718713832592000&amp;usg=AOvVaw0cVE4Ot3bKw9I-Vv-TIcAZ">
                        <span>
                          
                        </span>
                        <span style="color:#fff;background-color:#007df2;border-radius:4px;font-size:15px;text-decoration:none;text-align:center;display:inline-block;min-width:210px;padding:16px 32px;max-width:100%;line-height:120%;text-transform:none">
                         ${buttonText}  
                        </span>
                        <span>
                          
                        </span>
                      </a>
                      <div>
                        <p style="font-size:14px;line-height:1.5;margin:16px 0;color:#484848">
                          Can't click the button? Here's the link for your
                          convenience:
                          <a style="color:#067df7;text-decoration:none" href="${url}" target="_blank" data-saferedirecturl="https://www.google.com/url?q=${url}&amp;source=gmail&amp;ust=1718713832592000&amp;usg=AOvVaw0cVE4Ot3bKw9I-Vv-TIcAZ">
                            ${url}
                          </a>
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <hr style="width:100%;border:none;border-top:1px solid #eaeaea;border-color:#e1e1e1;margin:20px 0">
                <p style="font-size:12px;line-height:15px;margin:0 auto;color:#b7b7b7;text-align:left;margin-bottom:50px">
                  You have received this email notification because you
                  are a member of tackl. If you no longer wish to receive these emails, please contact
                  tackl team.
                </p>
              </div>
            </td>
          </tr>
        </tbody></table>
      </td>
    </tr>
  </tbody>
</table>
</div>
`
}

export const emailOnHomeOwnerSignup = (recipientEmail, userName) => {
  const subject = "Let's Tackl it together!";
  const bodyTitle = "Welcome to Tackl!";
  const body =
    `
You can get a quote for your property maintenance or repair project in hours without talking to anyone. Post your first project - it's free! You will get a line item quote back, and if you want to move forward and need a contractor, you can match with one of our vetted Tackl Pros!
<br/>
<br/>
Give us a shout if you have any questions at <a href='mailto:support@tacklpro.com'>support@tacklpro.com</a> or text us at <a href='sms:8179683936'>817-968-3936</a> and get ready to Tackl it!
  `
  const buttonText = "Post a Project";

  return sendEmail(recipientEmail, subject, getEmailTemplate(bodyTitle, body, getPostNewProjectUrl(), buttonText));
}

export const emailOnSubmitForQuote = (recipientEmail, listing) => {
  const subject = "Tackl Project Submitted!!";
  const bodyTitle = "Get excited!";
  const body = "Thank you for your project submission to Tackl. We'll get to work on your quote and notify you quickly with an email when the quote is available. You can always check on progress at <a href='" + "www.tacklnow.com" + "'>tacklnow.com</a>. If you need support in the meantime, reach out to <a href='" + "mailto:support@tacklpro.com" + "'>support@tacklpro.com</a>."
  const buttonText = "Review Project Details";

  return sendEmail(recipientEmail, subject, getEmailTemplate(bodyTitle, body, getEditListingUrl(listing, 'quote'), buttonText));
}

export const emailOnQuoteAdded = (recipientEmail, listing) => {

  const subject = "Quote available for your Tackl project!";
  const bodyTitle = "Get ready to Tackl it!!";
  const body = "A quote has been added to your project. Please login to <a href='" + "www.tacklnow.com" + "'>tacklnow.com</a> to review your quote and move forward with next steps in the Tackl process. Tackl will also send a quote to you via email.";
  const buttonText = "Review Project Details";

  return sendEmail(recipientEmail, subject, getEmailTemplate(bodyTitle, body, getEditListingUrl(listing, 'quote'), buttonText));
}

export const emailOnPublishProject = (recipientEmail, listing) => {

  const subject = "Get ready to get matched!!";
  const bodyTitle = "Get ready to check this project off your list!";
  const body = "We are working on matching a contractor to your project based off of your line item quote and project needs. Next steps will be assigning contractor, reviewing onsite if needed, and then submitting your deposit once contractor schedules start date and finalizes details. We can't wait to see you Tackl it!";
  const buttonText = "Review Project Details";

  return sendEmail(recipientEmail, subject, getEmailTemplate(bodyTitle, body, getListingUrl(listing), buttonText));
}

const sendEmail = (recipientEmail, subject, text) => {
  const requestOptions = {
    recipient: recipientEmail.trim(),
    subject: subject,
    text: text,
  };

  post('/api/sendgrid-listing-email', requestOptions)
    .then(response => {

    })
    .catch(e => {

    });
}