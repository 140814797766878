import React, { useEffect } from 'react';
import enFlag from './icons/enFlag.png';
import esFlag from './icons/esFlag.png';
import css from './LanguageSwitcher.module.css';

import { Menu, MenuLabel, MenuContent, MenuItem } from '../../components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { handleSwitchLanguage } from '../../ducks/ui.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

const LanguageSwitcherComponent = props => {
  const { onHandleSwitchNewLanguage, currentUser, language, onUpdateProfile, onMobile } = props;
  //set language to saved value if any
  const [isMenuOpen, setMenuOpen] = React.useState(false);

  useEffect(() => {
    const savedLanguageMaybe =
      currentUser?.attributes?.profile?.protectedData?.language ||
      window.sessionStorage.getItem('sessionLanguage') ||
      language;
    if (language !== savedLanguageMaybe) {
      onHandleSwitchNewLanguage(savedLanguageMaybe);
    }
  }, []);

  const handleLanguageSwitch = async lang => {
    setMenuOpen(current => !current);
    await onHandleSwitchNewLanguage(lang);

    if (typeof window !== 'undefined') {
      window.emitter.emit('sessionLanguage', lang);
    }

    //update currentUser
    if (currentUser?.id?.uuid) {
      await onUpdateProfile({
        protectedData: {
          language: lang,
        },
      });
    } else {
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('sessionLanguage', lang);
      }
    }
  };

  let flagLabel = enFlag;
  switch (language) {
    case 'en':
      flagLabel = enFlag;
      break;
    case 'es':
      flagLabel = esFlag;
      break;
  }

  return (
    <div className={onMobile ? css.langSwitchWrapperMobile : css.langSwitchWrapper}>
      <Menu isOpen={isMenuOpen} onToggleActive={setMenuOpen}>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          {<img src={flagLabel} className={css.icon} />}
        </MenuLabel>
        <MenuContent className={onMobile ? css.profileMenuContentMobile : css.profileMenuContent}>
          <MenuItem key="en">
            <div className={css.labelWrapper} onClick={() => handleLanguageSwitch('en')}>
              <img
                src={enFlag}
                className={css.icon}
                //alt="nachhaltiger Tourismus/sustainable tourism"
              />
              <span className={css.mobileLabel}>
                {/* <FormattedMessage id={'LanguageSwitcher.en'} /> */}
                EN
              </span>
            </div>
          </MenuItem>
          <MenuItem key="es">
            <div className={css.labelWrapper} onClick={() => handleLanguageSwitch('es')}>
              <img src={esFlag} className={css.icon} 
              //alt="nachhaltiger bnb/sustainable bnb" 
              />
              <span className={css.mobileLabel}>
                {/* <FormattedMessage id={'LanguageSwitcher.es'} /> */}
                ES
              </span>
            </div>
          </MenuItem>
        </MenuContent>
      </Menu>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { language } = state.ui;

  return {
    currentUser,
    language,
  };
};

const mapDispatchToProps = dispatch => ({
  onHandleSwitchNewLanguage: newLanguage => dispatch(handleSwitchLanguage(newLanguage)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const LanguageSwitcher = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LanguageSwitcherComponent);

export default LanguageSwitcher;
