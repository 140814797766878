import { userTypes } from "./userTypes"

export const getIsAdmin = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes.profile.protectedData.userType === userTypes.admin;
}

export const getIsHomeOwner = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes.profile.protectedData.userType === userTypes.homeOwner;
}

export const getIsContractor = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes.profile.protectedData.userType === userTypes.contractor;
}


export const getHasSubscription = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes?.profile?.publicData?.hasSubscription === true;
}

export const getHasLiteSubscription = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes?.profile?.publicData?.hasLiteSubscription === true;
}
