import { storableError } from '../../util/errors';
import { post } from '../../util/api';

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/AdminDashboardPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/AdminDashboardPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/AdminDashboardPage/SEARCH_LISTINGS_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/AdminDashboardPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/AdminDashboardPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/AdminDashboardPage/UPDATE_LISTING_ERROR';
// ================ Reducer ================ //

const initialState = {
  searchInProgress: false,
  searchListingsError: null,
  updateListingInProgress: false,
  updateListingError: null,
  listingUpdatedSuccessfully: false,
  listings: [],
};


const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
        listings: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: payload.data,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case UPDATE_LISTING_REQUEST:
      return {
        ...state,
        updateListingInProgress: true,
        updateListingError: null,
        listingUpdatedSuccessfully: false
      };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        updateListingInProgress: false,
        listingUpdatedSuccessfully: true,
      };
    case UPDATE_LISTING_ERROR:
      console.error(payload);
      return {
        ...state, updateListingInProgress: false,
        listingUpdatedSuccessfully: false,
        updateListingError: payload
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});


export const updateListingRequest = params => ({
  type: UPDATE_LISTING_REQUEST,
  payload: { params },
});

export const updateListingSuccess = response => ({
  type: UPDATE_LISTING_SUCCESS,
  payload: { data: response },
});

export const updateListingError = e => ({
  type: UPDATE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const loadData = () => (dispatch, getState, sdk) => {

  dispatch(searchListingsRequest());

  return post('/api/fetch-listings-for-admin', {})
    .then(response => {

      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const updateListing = (params) => (dispatch, getState, sdk) => {
  dispatch(updateListingRequest());

  return post('/api/update-listing-for-admin', params)
    .then(response => {
      dispatch(updateListingSuccess());
      dispatch(loadData());
      return response;
    })
    .catch(e => {
      dispatch(updateListingError(storableError(e)));
      throw e;
    });
}
